import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/leo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Leo</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo Woman</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo Woman</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="font-bold text-orange-500 inline-block mr-2">Leo Women Traits:</p> Be in the spotlight, egoistic, straightforward, kind and big-hearted, creative and stylish
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Compatibility Signs for Leo Women:</p>Aries, Gemini, Libra, and Sagittarius
 <br/><br/>
With the Sun in the sign of Leo, women are ruled by their emotions—they are the center of the universe. They are drawn to the spotlight from the very beginning, and they will do whatever it takes to be the center of attention. This often translates into being a stage queen, whether it's a drama class, a school play, or a job where they get to be the center of attention. 
 <br/><br/>
The Sun in Leo is all about the here and now, and they are incredibly present at the moment.<br/><br/>
The women of the sign of Leo are some of the most vibrant and exciting individuals around. They possess the loudest laugh, the brightest smile, and the most confident strut among all signs. They are prone to self-promotion and will do whatever it takes to be the center of attention. They don't mind flaunting their wealth or power and are often surrounded by an aura of glamour and beauty.
 <br/><br/>
When it comes to their physicality, Leo women are the epitome of charisma. They have the loudest laugh, the brightest smile, and the most confident strut among all signs. They are the center of attention, and everyone else just naturally gravitates toward them. They want to be the center of the universe, and being the center of attention demonstrates that they are the most critical person in the room.
You are a witty, intelligent, and big dreamer. You fulfill your creative passions and also inspire everyone around you. However, you are truly unique and thrive on the things in life dear to you.
Your horoscope sign is a powerful tool for understanding your personality, your relationships, and love compatibility on a deeper level. Discover more about yourself with your free personalized janampatri!
Let's take a look at Leo's woman traits. <br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Be in the spotlight</p><br/>
 
You are a natural performer, and it shows in everything you do. You are comfortable performing in front of an audience and thrive on receiving accolades and attention. You always want to be center stage and are most content when there is someone else who could step into the spotlight at any moment. You feel most alive when performing, and it is your most significant source of comfort and self-expression.
 <br/><br/>
You have a strong sense of who you are, and you wear your confidence on your sleeve. You're not afraid to be the center of attention, and you tend to draw a lot of attention to yourself. It can be difficult for others to know how they should be acting around you because you're such an enigma. But being self-assured comes naturally to you, and you're usually aware of how others perceive you.
 
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Kind and big-hearted</p><br/>
You are incredibly respectful of your elders and the elderly, especially those not your own. You are very sensitive and loving to others. You are a kind person, willing to help anyone in need no matter the situation. You take care of your family and friends first, even if it means making sacrifices for them. You are generous and will go out of your way to make others happy. You know how to cheer people up, even if you don't have much yourself. You are selfless and put others first, even if it means being uncomfortable sometimes.
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">Straight forward</p><br/>
Leo women are very straightforward; you always speak your mind and get into arguments without hesitation. They like to control everything in life and tend not to listen to any advice given by anyone. Because of this, they tend to get into arguments and quarrels with their spouses or lovers. As you may be guiding them towards growth and betterment. 
 <br/><br/>
You always want the best for yourself and your close ones. You dare to speak your mind when you do so, and you are willing to stand up for what you believe in, no matter what others think of you. You are eager to stand up for what you believe in, no matter what others think of you. You are willing to stand up for what you believe in, no matter what others think of you. You are eager to stand up for what you believe in, no matter what others think of you.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Creative and stylish</p><br/>
You have a creative inner drive to produce creativity and art in whatever you do. You are very particular about your choice and tastes. You have an excellent eye for detail. You enjoy dressing up, and you want to look good. You like to present yourself well, and you feel a sense of accomplishment when you look your best. You care about how you look, and you will do anything to look your best.
 
You are driven by the desire to look your best. You are constantly preening, fussing, and primping to make yourself look and feel your best. You are vain, and you tend to spend inordinate amounts of time and money on beauty products, clothes, and accessories. You are constantly aware of your appearance, and you are acutely sensitive to any flaws or perceived flaws.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Egoistic</p><br/>
Your ego is tender, and you may tend to wear your heart on your sleeve. You tend to be enraged and hot-tempered if someone steps out of line. When someone disrespects you or tries to control you, you will become extraordinarily ferocious and dramatically tempestuous. Your body will feel like it is on fire, and you will feel like you have been struck by lightning. This is the body of the ego in a state of a violent eruption. You can see just how vulnerable the ego is to us when we behave this way.
You were born free and proud, with the ability and desire to take charge of your own life. You love your independence and strive for self-sufficiency as much as possible. You do not like being controlled or told what to do. Your free spirit does not like to be confined, and you often find yourself pushing away the people who want to hold you somehow.
 <br/><br/>
 
 <p className="">Know your unique strengths and weaknesses with the help of your Janampatri </p>
 
 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Leo Women</p>
 <br/>
Women are attracted to men because of their fantastic set of physical features. It is pretty simple for a woman to see the man she likes. For example, a man should have a pleasing smile, a nice body, and a kind and gentle personality. Of course, the woman should first like him, and only then will she begin to pay attention to his physical features.
 <br/><br/>
 
Leo women are regal, confident, and effortlessly powerful. You can't afford to go wrong in showing your affection. If you are patient and sincerely show your appreciation, she will appreciate it. If you try to force your love on her, she will sense your insincerity, and you might lose her.
When it comes to a Leo woman, confidence is vital. They don't need to be told how much they are loved; they know intuitively. They are also regal creatures, which means they have high standards and won't settle for anything less than the best. They are powerful and will never do anything half-heartedly, so don't expect to come home after work and make dinner for your Leo woman.
 <br/><br/>
Here are a few tips that will help catch and impress Leo women. Are you ready to discover the magic?
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Pile on the flattery</p><br></br>Pile on the flattery<br></br>
You need to pile her on the flattery by making her think that in your eyes, she is the most wonderful girl in the world. The only way to do this is to make her believe that she is the most wonderful girl in the world in your eyes. There is no need to resort to subtleties. It would be best if you did not rudely go about this. You have to use your charm and good looks as much as possible.
 <br/><br/>
There is no need to resort to subtleties. It would help if you did not go about this roundabout. You are an absolute genius and a great person.<br/><br/>
Make sure you praise her all the time. And be honest. Tell her that you love her.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Let her take the lead</p>  <br/>
On the other hand, your girlfriend needs you to lead in arguments, make arrangements, and take charge of the relationship. You need to be on the same page like her to keep her heart. You shouldn't avoid arguments with her, but you should allow her to take the lead in these discussions. This will allow her to work through her thoughts and gain clarity on her position, which is always helpful in making better decisions moving forward. It will also show her that you respect her point of view, which is essential in winning her heart.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Show her lots of affection</p> <br/>
If you're the most loving, affectionate, and secure person she will ever meet, she will ultimately submit to your will. You will have complete control over her emotions, and she will feel safe and secure around you. She will be open with her vulnerabilities, and you will be the go-to person for advice and support. You'll know just what to say to make her happy, and she will be eternally grateful.
 <br/><br/>
 When she feels low, you should try to console her and make her feel better. You can do this by giving her a tight hug or kiss on the cheek when she needs it. You can also express how much you care about her and reassure her that everything will be okay. This will boost her ego and make her feel better.
 <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
